import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { LandingPageLayout } from "../../components/layouts";
import RegisterOrganisation from "./RegisterOrganisation";
import UserContext from "../AppRouter/UserContext";

export default function AdminLandingPage() {
  const { userData } = useContext(UserContext);
  const firstLog = window.location.href.toString().includes("manage-employees");
  return (
    <>
      <LandingPageLayout>
        {firstLog && <Redirect to="admin/manage-employees" />}
        {userData.organizationExist && !firstLog ? (
          <Redirect to="admin/manage-assigments" />
        ) : (
          <RegisterOrganisation />
        )}
      </LandingPageLayout>
    </>
  );
}
