import { useState, useEffect, Dispatch, SetStateAction, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  makeStyles,
  Checkbox,
} from "@fluentui/react-components";
import {
  AppButton,
  AppUserSelect,
  AppDateSelect,
  AppDivider,
  AppTimeInput,
  AppSelect,
  AppTextArea,
} from "../common";
import { useForm } from "react-hook-form";
import usersService from "../../services/users.service";
import dataTimeService from "../../services/dataTime.service";
import { IUserSelectable } from "../../services/types/users";
import { overtimeTypeEnum, userRoleEnum } from "../../utils/enums";
import appErrorToast from "../common/AppErrorToast";
import UserContext from "../../modules/AppRouter/UserContext";
import OvertimeAssigments from "../../services/overtimeAssigments.service";
import { useParams } from "react-router-dom";

interface IFormData {
  employeeId: string;
  assigmentDate: Date;
  duration: string;
  type: "Wypłata" | "Do odbioru" | null;
  applicationType: string,
  assignmentDescription: string;
  generateApplication: boolean;
}
interface IDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  formData: IFormData,
  refresh: () => void
}

const useStyles = makeStyles({
  dialogContainer: {
    maxWidth: "450px",
  },
});

const ModifyAssignmentDialog: React.FC<IDialogProps> = ({
  open,
  setOpen,
  formData,
  refresh
}) => {
  const styles = useStyles();
  const { id } = useParams<{ id: string; }>();
  const { register, handleSubmit, reset, watch, setValue, control, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useContext(UserContext);
  const applicationTypeFieldValue = watch('applicationType');
  const [userOptions, setUserOptions] = useState<IUserSelectable[]>([]);

  useEffect(() => {
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    usersService.selectableUsers().then((users) => {
      if (userData.data.baseRole === userRoleEnum.Employee) {
        users = users.filter(x => x.id === userData.data.id);
      }
      setUserOptions(users);
    });

    setValue('assignmentDescription', formData.assignmentDescription);
    setValue("generateApplication", formData.generateApplication);
    setValue("applicationType", formData.applicationType === "Leaving" ? "Wyjście" : "Nadgodziny");
  }

  async function handleFormSubmit(data: IFormData) {
    setIsLoading(true);

    if (dataTimeService.convertTimeToMin(data.duration) < 1) {
      appErrorToast("Ustaw prawidłową liczbę nadgodzin");
      setIsLoading(false);
      return;
    }

    await OvertimeAssigments.editAssigment({
      employeeId: data.employeeId,
      assignmentDate: data.assigmentDate,
      duration: dataTimeService.convertTimeToMin(data.duration),
      type:
        data.type === "Wypłata"
          ? overtimeTypeEnum.TimeToPay
          : overtimeTypeEnum.TimetoCollection,
      assignmentDescription: data.assignmentDescription,
      generateApplication: data.generateApplication
    }, id);
    reset();
    setIsLoading(false);
    setOpen(false);
    refresh();
    setInitialValues();
  }
  const dialogOpenChange = () => {
    setOpen(false);
  };
  
  const handleApplicationTypeChanges = () => {
    if (applicationTypeFieldValue === "Nadgodziny") {
      setValue("generateApplication", false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={dialogOpenChange}>
      <DialogSurface className={styles.dialogContainer}>
        <form>
          <DialogBody>
            <DialogTitle>{userData.data.baseRole === userRoleEnum.Admin ? "Formularz zlecenia nadgodzin" : "Wniosek o nadgodziny/wyjście"}</DialogTitle>
            <DialogContent>
              <AppDivider />
              <AppUserSelect
                label="Wybierz pracownika"
                options={userOptions}
                formHandler={register("employeeId", {
                  required: true,
                })}
              />
              <AppDivider />
              <AppDateSelect
                label="Wybierz datę"
                formHandler={register("assigmentDate", {
                  required: true,
                })}
                defaultValue={dataTimeService.dateToString(formData.assigmentDate)}
              />
              <AppDivider />
              <AppTimeInput
                type="time"
                label="Liczba godzin"
                formHandler={register("duration", {
                  required: {value: true, message: "Liczba godzin jest wymagana"},
                  min: 1,
                })}
                control={control}
                errors={errors}
                defaultValue={dataTimeService.convertFormat(parseInt(formData.duration))}
              />
              {userData.data.baseRole === userRoleEnum.Admin &&
              <>
                <AppDivider />
                <AppSelect
                  label="Typ"
                  options={["Wypłata", "Do odbioru"]}
                  formHandler={register("type", { required: true })}
                />
              </>}
              {userData.data.baseRole === userRoleEnum.Employee &&
              <>
                <AppDivider />
                <AppSelect
                  label="Typ"
                  options={["Nadgodziny", "Wyjście"]}
                  formHandler={register("applicationType", { required: true })}
                  onClick={handleApplicationTypeChanges}
                  disabled
                />
                {applicationTypeFieldValue === "Wyjście" &&
                <>
                  <AppDivider />
                  <Checkbox
                    label="Odrabiam tego samego dnia"
                    {...register("generateApplication")}
                    checked={watch('generateApplication')}
                    disabled
                  />
                </>}
              </>}
              <AppDivider />
              <AppTextArea 
                label="Opis"
                formHandler={register("assignmentDescription", {maxLength: 1000})}
              />
            </DialogContent>
            <DialogActions>
              <AppButton 
                text="Anuluj" 
                appearance="secondary" 
                onClick={() => { setOpen(false); }}
              />
              <AppButton
                text="Zapisz"
                disabled={isLoading}
                appearance="primary"
                onClick={handleSubmit((data) => {
                  handleFormSubmit(data as IFormData);
                })}
              />
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};

export default ModifyAssignmentDialog;
